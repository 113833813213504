.upper-select-bar {
    background: #bfd6d5;
    padding-top: 2%;
    padding-bottom: 2%;
}
.margin {
    width: 15em;
    border-radius: 10px;
}
.MuiContainer-maxWidthMd .overview {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 1.2em;
    // margin-top: 2em;
}
.MuiCardContent-root .router-card-text {
    font-size: large;
    display: flex;
    gap: 1em;
    color: white;
}
.MuiCardContent-root .router-card-count {
    font-size: 24px;
    font-weight: 600;
    color: limegreen;
    margin-top: 12px;
}
.MuiCardContent-root .router-card-count-danger {
    font-size: 24px;
    font-weight: 600;
    color: red;
    margin-top: 12px;
}
.MuiContainer-maxWidthMd .device-text {
    color: black;
    font-size: x-large;
    font-weight: 500;
}

.MuiContainer-maxWidthMd .device-btns {
    border-radius: 23px;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 5px 15px;
    font-size: smaller;
    margin-left: 3px;
}
.MuiContainer-maxWidthMd .device-btns:focus {
    background-color: #b6fcbd;
}
.device-select {
    width: -webkit-fill-available;
}
.MuiGrid-item .search-btn {
    background-color: #40c706;
    color: white;
}
.cimb-text {
    font-size: large;
    font-weight: 500;
}
.hr {
    width: 86%;
    background-color: #faf8f7;
    margin-left: 1em;
    height: 1px;
    border: none;
}
.right-icon {
    color: #40c706;
}
.card-sub-head {
    font-size: small;
    color: darkgray;
}
.card-head-val {
    font-size: small;
    float: right;
}
.MuiCardContent-root .card-rows {
    margin: 5px 0px 5px 0px;
}
.router-card-icon {
    height: 2.3em;
    text-align: center;
    width: 2.3em;
    border-radius: 6px;
    background-color: #40c706;
    display: inline-block;
    padding-top: 5px;
    color: white;
}
.router-card-icon-offline {
    height: 2.3em;
    text-align: center;
    width: 2.3em;
    border-radius: 6px;
    background-color: #ff0000;
    display: inline-block;
    padding-top: 5px;
    color: white;
}
.model-container .model-close-btn {
    border: 1px solid #40c706;
    width: -webkit-fill-available;
    color: #40c706;
}
.model-container .model-submit-btn {
    border: 1px solid #40c706;
    background-color: #40c706;
    width: -webkit-fill-available;
    color: white;
}
.modal {
    display: 'flex';
    align-items: 'center';
    justify-content: 'center';
}
.model-container {
    width: 35em;
    text-align: center;
}
.MuiGrid-item .input-data-entry {
    width: -webkit-fill-available;
}
.input-para-text {
    text-align: initial;
    margin-bottom: 5px;
}
.model-data-grid {
    background-color: #f7fcfc;
    padding: 1em;
    border-radius: 5px;
    margin-top: 1em;
    margin-bottom: 1em;
}
.model-head {
    display: flex;
}
.model-data-values {
    font-size: medium;
    font-weight: 500;
}
.model-data-key {
    font-size: 14px;
}
.model-data-block {
    text-align: initial;
}
.top-card-icon {
    background: dimgrey;
    border-radius: 5px;
    width: 28px;
    opacity: 0.7;
    padding: 3px;
}
.bg-gradent-danger {
    background-image: linear-gradient(white, #ffebf8);
    background-size: 100% 20%;
    background-repeat: no-repeat;
    background-position: bottom;
}
.bg-gradent-green {
    background-image: linear-gradient(white, #edfaf1);
    background-size: 100% 20%;
    background-repeat: no-repeat;
    background-position: bottom;
}
.MuiInputBase-formControl #demo-customized-select {
    height: 12px;
    text-align: center;
    background-color: white;
    color: black;
    border-radius: 0px;
}
.MuiFormControl-root .MuiInputBase-formControl .MuiSelect-icon {
    color: black;
}
.bg-img-container .MuiContainer-maxWidthMd {
    max-width: 73em;
}
div .MuiContainer-maxWidthMd {
    max-width: 73em;
}
.MuiCircularProgress-indeterminate {
    position: absolute;
    left: 45%;
}
.card-progress-container {
    height: 15em;
}
// .MuiGrid-container .padding-null{
//   padding: 0px;
// }
// .bg-img-container{
//   padding-top: 2em;
// }

// small devices
@media only screen and (max-width: 768px) {
    .MuiGrid-item .margin {
        width: -webkit-fill-available;
        margin-left: 10px;
        margin-right: 10px;
    }
    .upper-select-bar {
        padding-bottom: 6%;
    }
    .MuiGrid-item .search-btn {
        width: -webkit-fill-available;
    }
    .model-container {
        width: 21em;
        height: 98vh;
    }
    .input-para-text {
        font-size: 12px;
    }
}

//   Large Devices
@media only screen and (min-width: 768px) {
    .select-bar-content {
        position: relative;
        right: 2%;
    }
}
