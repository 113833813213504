.top-btns {
    width: -webkit-fill-available;
}
.MuiGrid-item .active-tab {
    background-color: #40c706;
}
.MuiGrid-item .active-tab:hover {
    background-color: #40c706;
}
.device-head {
    font-size: 23px;
    font-weight: 500;
    color: midnightblue;
    margin-top: 12px;
    margin-bottom: 5px;
}
#demo-customized-select {
    background: darkslategray;
    color: white;
}
.MuiInputBase-formControl .MuiSelect-icon {
    color: white;
}
.MuiGrid-root .primary-btn {
    background-color: #40c706;
    color: white;
}
#date-picker-inline {
    width: -webkit-fill-available;
}
.utilities-bar {
    align-items: center;
}
.MuiGrid-item .toggle-btn {
    border-radius: 2em;
    margin-left: 5px;
    margin-right: 5px;
    font-size: x-small;
}
.text-center {
    text-align: center;
    background-color: white;
    height: 20em;
}
.toggle-label {
    font-size: 12px;
}
.MuiGrid-item .download-btn {
    color: #40c706;
    border: 1px solid #40c706;
}
.MuiGrid-item .bg-active {
    background-color: rgb(182, 252, 189);
}

/* mobile screens */
@media only screen and (max-width: 768px) {
    .MuiGrid-root .primary-btn {
        width: -webkit-fill-available;
    }
    .MuiGrid-root .primary-btn:hover {
        background-color: #40c706;
    }
}
