.profile-head-text {
    font-weight: 400;
    color: #115e67;
}
.profile-header .avatar {
    text-align: center;
    width: 6em;
    height: 6em;
}
.profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.margin-top {
    margin-top: 1em;
}
.MuiGrid-item .update-pw-btn {
    background-color: #40c706;
    color: white;
    width: 20em;
}
.MuiGrid-item .update-pw-btn:hover {
    background-color: #40c706;
    color: white;
    width: 20em;
}
.MuiGrid-item .reset-pw-btn {
    color: #40c706;
    width: 20em;
}
.color-green {
    color: #40c706;
}
.check-text {
    vertical-align: super;
}
.eye-icon {
    position: relative;
    right: 9%;
    top: 8px;
}
.pw-input {
    width: 20em;
}
.buttons-part .submit-btn {
    background-color: #40c706;
    color: white;
    width: 24.6em;
}
.buttons-part .submit-btn:hover {
    background-color: #40c706;
    color: white;
    width: 24.6em;
}
.buttons-part .cancel-btn {
    border: 1px solid #40c706;
    color: #40c706;
    width: 24.6em;
    margin-top: 1em;
}
.margin-top-small {
    margin-top: 0.5em;
}
.input-pw-section {
    display: flex;
}

/* small devices */
@media only screen and (max-width: 768px) {
    .profile-head-text {
        text-align: center;
    }
}
