.user-info {
    display: flex;
    position: relative;
    top: 10px;
    flex-direction: column;
    margin-right: 1em;
}
.role-text {
    font-size: smaller;
    font-weight: 600;
    line-height: 0;
}
.vertical {
    border-left: 1px solid lightgray;
    height: 1.5em;
    left: 50%;
    right: 50%;
}
.logout-icon {
    margin-left: 0.5em;
    margin-right: 0.5em;
}
.avatar {
    margin-right: 0.5em;
}
.form-inline {
    position: absolute;
    right: 1px;
    bottom: 0.5em;
}
.highlet {
    font-weight: 500;
    color: black;
}

/* small screen */
@media only screen and (max-width: 768px) {
    .navbar-brand {
        position: relative;
        right: 40%;
    }

    .form-inline {
        position: relative;
    }
}

/* big screen */
@media only screen and (min-width: 768px) {
    /* .navbar-brand{
        position: relative;
        right: 40%;
    } */
}
