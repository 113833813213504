* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    height: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    ::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        @media only screen and (max-width: 600px) {
            height: 5px;
            width: 5px;
        }
    }
    ::-webkit-scrollbar-track {
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb {
        background: rgb(196, 196, 196);
        border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
        background: #888;
        border-radius: 10px;
    }
}

body {
    background-color: #f4f6f8;
    height: 100%;
}

body,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 300;
    line-height: 1.5em;
}

a {
    text-decoration: none;
}

#root {
    height: 100%;
    background-color: #f4f6f8;
}
